/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */

import fetchWrapper from 'utils/fetchWrapper';

const apiUrl = process.env.REACT_APP_API_URL;

async function contactUs(data) {
  try {
    const response = await fetchWrapper.post(`${apiUrl}/api/support`, data);
    return response;
  } catch (error) {
    console.error('Error in contactUs:', error);
    throw error;
  }
}

export default contactUs;
