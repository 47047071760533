// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .custom-google-maps__container {
    height: 219.63px;
  }
}

@media only screen and (max-width: 800px) {
  .cta-property-cooperation__btn {
    padding: 10.67px 11.33px;
  }

  .cta-property-cooperation__btn .text {
    margin-left: 5px;
    font-size: 0.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .custom-google-maps__container {\n    height: 219.63px;\n  }\n}\n\n@media only screen and (max-width: 800px) {\n  .cta-property-cooperation__btn {\n    padding: 10.67px 11.33px;\n  }\n\n  .cta-property-cooperation__btn .text {\n    margin-left: 5px;\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
