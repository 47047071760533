import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortenNumber } from '../../utils/common';
import defaultImage from '../../assets/pexels-pixabay-164558 2.png';
import shower from '../../assets/techpolitan-shower.svg';
import bed from '../../assets/techpolitan-bed.svg';
import marker from '../../assets/techpolitan-marker.svg';
import call from '../../assets/techpolitan-phone.svg';
import '../../styles/components/cards/card-product.css';

function CardProduct({ detail }) {
  return (
    <div className="card-product">
      <div className="card-product__header">
        <Link to={`/properties/${detail.id}`}>
          <img src={detail.cover_image_url || defaultImage} alt="property" />
        </Link>
      </div>
      <div className="card-product__body">
        <Link to={`/properties/${detail.id}`} className="name">
          {detail?.name?.length > 18 ? `${detail.name.slice(0, 18)}...` : detail.name}
        </Link>
        <div className="location">
          <img src={marker} alt="location" />
          <span className="text">{detail.city_name}</span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="price">
            {detail && detail.price ? (
              <div className="normal">Rp {shortenNumber(detail?.price)}</div>
            ) : (
              <div className="normal">Contact for Price</div>
            )}
          </div>
          {detail && detail.phone_number ? (
            <Link className="btn-phone" to={`https://wa.me/${detail.phone_number}`} target="_blank">
              <img src={call} alt="call" />
              <span className="name">Hubungi</span>
            </Link>
          ) : (
            <Link className="btn-phone" to="/contact-us" target="_blank">
              <img src={call} alt="call" />
              <span className="name">Hubungi</span>
            </Link>
          )}
        </div>
      </div>
      <div className="card-product__footer">
        {detail.bedroom_total ? (
          <div className="card-product__footer-item">
            <img src={bed} alt="bedroom" />
            <span className="text">{detail.bedroom_total}</span>
          </div>
        ) : null}
        {detail.bathroom_total ? (
          <div className="card-product__footer-item">
            <img src={shower} alt="bathroom" />
            <span className="text">{detail.bathroom_total}</span>
          </div>
        ) : null}
        {detail.lot_area ? (
          <div className="card-product__footer-item">
            LT {detail.lot_area} M<sup>2</sup>
          </div>
        ) : null}
        {detail.floor_area ? (
          <div className="card-product__footer-item">
            LB {detail.lot_area} M<sup>2</sup>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const product = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  lot_area: PropTypes.number.isRequired,
  floor_area: PropTypes.number || null,
  bedroom_total: PropTypes.number || null,
  bathroom_total: PropTypes.number || null,
  phone_number: PropTypes.string.isRequired,
  city_name: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
};

CardProduct.propTypes = {
  detail: PropTypes.shape(product).isRequired,
};

export default CardProduct;
