// format money with currency
// const formatMoney = (money, currency = 'Rp.', decimalCount = 0, decimal = ',', thousands = '.') => {
//   try {
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

//     const negativeSign = money < 0 ? '-' : '';

//     const i = parseInt((money = Math.abs(Number(money) || 0).toFixed(decimalCount))).toString();
//     const j = i.length > 3 ? i.length % 3 : 0;

//     return `${currency} ${negativeSign}${j ? i.substr(0, j) + thousands : ''}${i
//       .substr(j)
//       .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
//       decimalCount
//         ? decimal +
//           Math.abs(money - i)
//             .toFixed(decimalCount)
//             .slice(2)
//         : ''
//     }`.trim();
//   } catch (e) {
//     console.log(e);
//   }
// };

// function timeDiffDisplay(lastSeen) {
//   // Parse the lastSeen string to a Date object
//   const lastSeenDate = new Date(lastSeen);

//   // Get the current time
//   const currentTime = new Date();

//   // Calculate the time difference in milliseconds
//   const timeDifference = currentTime - lastSeenDate;

//   // Convert milliseconds to seconds
//   const secondsDifference = Math.floor(timeDifference / 1000);

//   // Calculate minutes, hours, and days
//   const minutesDifference = Math.floor(secondsDifference / 60);
//   const hoursDifference = Math.floor(minutesDifference / 60);
//   const daysDifference = Math.floor(hoursDifference / 24);

//   // Construct the message based on the time difference
//   let message;
//   if (secondsDifference < 60) {
//     message = 'Online just now';
//   } else if (minutesDifference < 60) {
//     message = `Online ${minutesDifference} minutes ago`;
//   } else if (hoursDifference < 24) {
//     message = `Online ${hoursDifference} hours ago`;
//   } else {
//     message = `Online ${daysDifference} days ago`;
//   }

//   return message;
// }

const formatCurrency = (money) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(money);
};

export function shortenNumber(value) {
  let shortened;
  switch (true) {
    case value >= 1.0e9:
      shortened = `${value / 1.0e9} Miliar`;
      break;
    case value >= 1.0e6:
      shortened = `${value / 1.0e6} Juta`;
      break;
    case value >= 1.0e3:
      shortened = `${value / 1.0e3} k`;
      break;
    default:
      shortened = value;
      break;
  }
  return shortened;
}

// function test(labelValue) {
//   // Nine Zeroes for Billions
//   return Math.abs(Number(labelValue)) >= 1.0e9
//     ? `${Math.abs(Number(labelValue)) / 1.0e9}B`
//     : // Six Zeroes for Millions
//       Math.abs(Number(labelValue)) >= 1.0e6
//       ? `${Math.abs(Number(labelValue)) / 1.0e6}M`
//       : // Three Zeroes for Thousands
//         Math.abs(Number(labelValue)) >= 1.0e3
//         ? `${Math.abs(Number(labelValue)) / 1.0e3}K`
//         : Math.abs(Number(labelValue));
// }

export default formatCurrency;
