import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useViewersStore = create(
  persist(
    (set) => ({
      viewers: 0,
      setViewers: (data) => set({ viewers: data }),
    }),
    {
      name: 'viewers-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage),
      skipHydration: false, // (optional) by default, 'localStorage' is used
    },
  ),
);

export default useViewersStore;
