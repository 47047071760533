// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_container {
  width: 100%;
  min-height: auto;
  height: auto;
  background-color: #003459;
  padding-left: 12.48%;
  padding-right: 12.48%;
  padding-top: 57px;
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.footer_logo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.footer_logo img {
  height: 70px;
}

.footer_rights .web-name,
.footer_rights .trademark {
  color: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  line-height: normal;
}

.footer_rights .web-name {
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;
  margin-bottom: 6px;
}

.footer_rights .trademark {
  font-weight: 400;
  font-size: 0.938rem;
  margin: 0;
}

@media only screen and (max-width: 1439px) {
  .footer_container {
    padding-left: 5.12%;
    padding-right: 5.12%;
  }
}

@media only screen and (max-width: 600px) {
  .footer_logo img {
    height: 70px;
    width: 302.83px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE;IACE,mBAAmB;IACnB,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".footer_container {\n  width: 100%;\n  min-height: auto;\n  height: auto;\n  background-color: #003459;\n  padding-left: 12.48%;\n  padding-right: 12.48%;\n  padding-top: 57px;\n  padding-bottom: 44px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  transition: all 0.3s ease;\n}\n\n.footer_logo {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n}\n\n.footer_logo img {\n  height: 70px;\n}\n\n.footer_rights .web-name,\n.footer_rights .trademark {\n  color: #ffffff;\n  font-family: 'Ubuntu', sans-serif;\n  font-style: normal;\n  line-height: normal;\n}\n\n.footer_rights .web-name {\n  font-weight: 700;\n  font-size: 1.125rem;\n  margin: 0;\n  margin-bottom: 6px;\n}\n\n.footer_rights .trademark {\n  font-weight: 400;\n  font-size: 0.938rem;\n  margin: 0;\n}\n\n@media only screen and (max-width: 1439px) {\n  .footer_container {\n    padding-left: 5.12%;\n    padding-right: 5.12%;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .footer_logo img {\n    height: 70px;\n    width: 302.83px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
