/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

const CustomMenu = React.forwardRef(({ children, style, className, ariaLabelledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={ariaLabelledBy}>
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
};

export default CustomMenu;
