/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import 'styles/pages/contact-us/contact-us.css';
import { MdLocationPin, MdEmail, MdLocalPhone } from 'react-icons/md';
import contactUs from 'services/contact.service';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleSendEmail = useMutation({
    mutationFn: (data) => contactUs(data),
    onSuccess: (res) => {
      console.log('Email sent successfully:', res);
      reset();
    },
    onError: (error) => {
      console.error('Error sending email:', error);
    },
  });

  const sendEmail = (data) => {
    console.log('Sending email with data:', data);
    handleSendEmail.mutate(data);
  };

  return (
    <main className="main-container pb-5">
      <div className="contact-us-section_a">
        <h1>Hubungi Kami</h1>
      </div>
      <div className="contact-us-section_b">
        <div className="container-fluid p-0 m-0 mt-5">
          <div className="row p-0 m-0">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0 m-0">
              <p className="contact-us-section_b-text-a">
                Apakah anda tertarik dengan platform dan service kami? Silahkan isi form di bawah
                ini dan kami akan segera merespon anda.
              </p>
              <form onSubmit={handleSubmit(sendEmail)}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Your Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    {...register('name', { required: true })}
                    className="form-control"
                  />
                  {errors.name && (
                    <span className="contact-section-a--text-f">This field is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    Phone Number *
                  </label>
                  <input
                    type="tel"
                    id="phone_number"
                    placeholder="+62"
                    {...register('phone_number', { required: true })}
                    className="form-control"
                  />
                  {errors.phone_number && (
                    <span className="contact-section-a--text-f">This field is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email_address" className="form-label">
                    Your Email *
                  </label>
                  <input
                    type="email"
                    id="email_address"
                    {...register('email_address', { required: true })}
                    className="form-control"
                  />
                  {errors.email_address && (
                    <span className="contact-section-a--text-f">This field is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject *
                  </label>
                  <input
                    type="text"
                    id="subject"
                    {...register('subject', { required: true })}
                    className="form-control"
                  />
                  {errors.subject && (
                    <span className="contact-section-a--text-f">This field is required</span>
                  )}
                </div>
                <div className="mb-5">
                  <label htmlFor="question" className="form-label">
                    Your Question *
                  </label>
                  <textarea
                    id="question"
                    rows={4}
                    {...register('question', { required: true })}
                    className="form-control"
                  />
                  {errors.question && (
                    <span className="contact-section-a--text-f">This field is required</span>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  <button type="submit" className="submit-button">
                    <p className="text">Apply</p>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0 m-0 ">
              <div className="w-100 d-flex justify-content-lg-end mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0 mt-xxl-0">
                <div className="contact-section-b--text-b">
                  <p>PT. Qorser Teknologi</p>
                  <p>
                    <MdLocationPin />
                    Jl. Pajagalan No.53a, Karanganyar, Kec. Astana Anyar, Kota Bandung, Jawa Barat
                    40241
                  </p>
                  <p>
                    <MdEmail />
                    info@qorser.com
                  </p>
                  <p>
                    <a
                      href="https://wa.me/6281224396888"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: 'black' }}
                    >
                      <MdLocalPhone /> 081224396888
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
