// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-property_title {
  font-size: 1.5rem;
  line-height: 1.829rem;
  letter-spacing: -0.079rem;
  color: #333333;
  font-weight: 700;
}

.slider-property_nav {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.slider-property_btn-see-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 220px;
  border: 1px solid #05a8e8;
  border-radius: 10px;
  background-color: #ffffff;
  color: #05a8e8;
  font-weight: 600;
  letter-spacing: -0.43px;
  font-size: 1.063rem;
}

.slider-property_btn-nav {
  background-color: #ffffff;
  border: 2px solid #333333;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  z-index: 10000;
}

.slider-property_btn-nav.disabled {
  border: 2px solid #d7d7d7;
}

@media only screen and (max-width: 500px) {
  .slider-property_title {
    font-size: 0.875rem;
  }

  .slider-property_btn-see-more {
    font-size: 0.75rem;
    border-radius: 5px;
    width: 153px;
    height: 32px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/sliders/slider-property.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".slider-property_title {\n  font-size: 1.5rem;\n  line-height: 1.829rem;\n  letter-spacing: -0.079rem;\n  color: #333333;\n  font-weight: 700;\n}\n\n.slider-property_nav {\n  width: auto;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.slider-property_btn-see-more {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 40px;\n  width: 220px;\n  border: 1px solid #05a8e8;\n  border-radius: 10px;\n  background-color: #ffffff;\n  color: #05a8e8;\n  font-weight: 600;\n  letter-spacing: -0.43px;\n  font-size: 1.063rem;\n}\n\n.slider-property_btn-nav {\n  background-color: #ffffff;\n  border: 2px solid #333333;\n  border-radius: 50%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 2.5rem;\n  width: 2.5rem;\n  z-index: 10000;\n}\n\n.slider-property_btn-nav.disabled {\n  border: 2px solid #d7d7d7;\n}\n\n@media only screen and (max-width: 500px) {\n  .slider-property_title {\n    font-size: 0.875rem;\n  }\n\n  .slider-property_btn-see-more {\n    font-size: 0.75rem;\n    border-radius: 5px;\n    width: 153px;\n    height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
