/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import arrowRight from 'assets/techpolitan-arrow-right.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'styles/pages/property-cooperation/property-cooperation.css';

function PropertyCooperation() {
  return (
    <main className="main-container">
      <div className="property-cooperation-section-a--container">
        <div className="property-cooperation-section-a-i-inner-container">
          {/* <h1 className="property-cooperation-section-a--text-a">Kesempatan Kerjasama</h1>
          <h1 className="property-cooperation-section-a--text-a">Properti Menguntungkan!</h1> */}
          <h1 className="property-cooperation-section-a--text-a">
            Kesempatan Kerjasama Properti Menguntungkan!
          </h1>
          <p className="property-cooperation-section-a--text-b">
            Kami mengundang Anda untuk bergabung dalam kesempatan kerjasama properti yang
            menguntungkan ini. Dalam kerangka kerjasama ini, kami menawarkan kolaborasi yang saling
            menguntungkan untuk mengembangkan properti yang menjanjikan dan menghasilkan keuntungan
            yang berkelanjutan.
          </p>
          <div
            style={{ marginTop: '32px' }}
            className="w-100 d-flex flex-row justify-content-center align-items-center"
          >
            <Link to="/contact-us" className="btn-join-now">
              <img src={arrowRight} alt="arrow-right" />
              <span>Bergabung Sekarang</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PropertyCooperation;
