/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { carImages } from 'utils/constanta';
import DOMPurify from 'dompurify';
import formatCurrency, { shortenNumber } from 'utils/common';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, Polygon } from 'react-leaflet';
import propertyService from 'services/property.service';
import ImageGallery from 'react-image-gallery';
import ImageLeftNav from 'components/Buttons/ImageLeftNav';
import ImageRightNav from 'components/Buttons/ImageRightNav';
import SliderProperty from 'components/Sliders/SliderProperty';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import parse from 'html-react-parser';
import { ShimmerDiv } from 'shimmer-effects-react';
import marker from '../../assets/techpolitan-marker.svg';
import L from 'leaflet';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'styles/pages/properties/properties.css';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function PropertyDetails() {
  const { id } = useParams();
  const payload = {
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    sales_type_id: null,
    price_selection_id: null,
  };

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [propertyList, setPropertyList] = useState([]);

  async function fetchProperty() {
    try {
      setLoading(true);
      const response = await propertyService.detailProperty(id);

      const restructure = {
        ...response?.data,
        images: response?.data?.images?.map((x) => ({
          original: x.url,
          thumbnail: x.url,
        })),
        coords:
          response?.data?.coords?.map((coord) => ({
            lat: coord.latitude,
            lng: coord.longitude,
          })) || [],
      };

      setProperty(restructure);
    } catch (error) {
      throw await error;
    } finally {
      setLoading(false);
    }
  }

  async function fetchPropertyList() {
    try {
      const response = await propertyService.propertyList(payload);
      setPropertyList(response?.data?.content);
    } catch (error) {
      throw await error;
    }
  }

  useEffect(() => {
    fetchProperty();
    fetchPropertyList();
    // fetchWaterSourceList();
  }, []);

  const renderMap = () => {
    if (!property || !property.coords || property.coords.length === 0) {
      return <p>Coordinates not available for this property.</p>;
    }

    const coordinates = property.coords;

    const validCoordinates = coordinates.filter(
      (coord) => coord && typeof coord.lat === 'number' && typeof coord.lng === 'number',
    );

    if (validCoordinates.length === 0) {
      return <p>No valid coordinates available for this property.</p>;
    }

    const center = coordinates[0];

    return (
      <MapContainer
        center={center}
        zoom={18}
        style={{ height: '442px', width: '100%' }}
        scrollWheelZoom={false}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright" />

        {validCoordinates.length < 3 &&
          validCoordinates.map((coord, index) => (
            <Marker
              key={`marker-${property.id}-${coord.lat}-${coord.lng}`}
              position={[coord.lat, coord.lng]}
            >
              <Popup>
                <div>
                  <h3>{property.name}</h3>
                  <img
                    src={property.cover_image_url}
                    alt={property.name}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <p>
                    Price:{' '}
                    {property.price.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  </p>
                  <p>Updated at: {new Date(property.updated_at).toLocaleString()}</p>
                </div>
              </Popup>
            </Marker>
          ))}

        {validCoordinates.length >= 3 && (
          <Polygon
            positions={validCoordinates.map((coord) => [coord.lat, coord.lng])}
            pathOptions={{ color: 'red', fillColor: 'blue', fillOpacity: 0.2 }}
          />
        )}
      </MapContainer>
    );
  };

  return (
    <main className="main-container">
      <div className="detail-property-section-a--container">
        <div className="detail-property-section-a--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item bsPrefix="breadcrumb-item detail-property-breadcrumb-item" href="/">
              Home
            </Breadcrumb.Item>

            <Breadcrumb.Item bsPrefix="breadcrumb-item detail-property-breadcrumb-item" active>
              {property?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="detail-property-section-a--info-container">
          <div className="detail-property-section-a--info-content">
            <div className="detail-property-section-a--image-slider-container">
              {loading ? (
                <ShimmerDiv mode="light" height={1000} width={1000} />
              ) : (
                <ImageGallery
                  items={
                    property?.images && property.images?.length > 0 ? property.images : carImages
                  }
                  showPlayButton={false}
                  renderLeftNav={(onClick, disabled) => (
                    <ImageLeftNav onClick={onClick} disabled={disabled} />
                  )}
                  renderRightNav={(onClick, disabled) => (
                    <ImageRightNav onClick={onClick} disabled={disabled} />
                  )}
                  showThumbnails={false}
                />
              )}
            </div>
          </div>
          <div className="detail-property-section-a--info-content">
            <div className="property-card-price-detail-title">{property?.name}</div>
            <div className="property-card-price-detail-location">
              <img src={marker} alt="marker" height="12px" width="12px" />

              <div className="ms-2">
                <div className="app-color-text-1 card-property-type-i-body--location-text mb-1">
                  {property?.location}
                </div>
                <div className="app-color-text-1 card-property-type-i-body--location-text">
                  {property?.city_name}
                </div>
              </div>
            </div>

            {property && property.price ? (
              <div className="property-card-price-detail-discount-price">
                Rp {shortenNumber(property?.price)}
              </div>
            ) : (
              <div className="property-card-price-detail-discount-price">Contact for Price</div>
            )}

            {property && property.phone_number ? (
              <Link
                className="btn-ask-property"
                to={`https://wa.me/${property.phone_number}`}
                target="_blank"
              >
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2499 10.875H10.1862C2.81741 10.4513 1.77116 4.23375 1.62491 2.33625C1.61313 2.18872 1.63057 2.04031 1.67624 1.89953C1.72191 1.75875 1.79491 1.62836 1.89105 1.51584C1.9872 1.40333 2.1046 1.31088 2.23654 1.24381C2.36847 1.17675 2.51234 1.13637 2.65991 1.125H4.72616C4.87637 1.12485 5.02316 1.16982 5.14752 1.25406C5.27188 1.33831 5.36808 1.45795 5.42366 1.5975L5.99366 3C6.04854 3.13633 6.06216 3.28578 6.03283 3.42978C6.00349 3.57379 5.9325 3.706 5.82866 3.81L5.02991 4.61625C5.15468 5.32528 5.49423 5.97888 6.00262 6.48861C6.51101 6.99834 7.16371 7.33961 7.87241 7.46625L8.68616 6.66C8.79172 6.55731 8.92518 6.48797 9.06989 6.46063C9.2146 6.43329 9.36416 6.44915 9.49991 6.50625L10.9137 7.0725C11.0511 7.12983 11.1684 7.22678 11.2505 7.351C11.3327 7.47522 11.376 7.62108 11.3749 7.77V9.75C11.3749 10.0484 11.2564 10.3345 11.0454 10.5455C10.8344 10.7565 10.5483 10.875 10.2499 10.875Z"
                    fill="white"
                  />
                </svg>
                <span className="ms-1">Tanya Properti</span>
              </Link>
            ) : (
              <Link className="btn-ask-property" to="/contact-us" target="_blank">
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2499 10.875H10.1862C2.81741 10.4513 1.77116 4.23375 1.62491 2.33625C1.61313 2.18872 1.63057 2.04031 1.67624 1.89953C1.72191 1.75875 1.79491 1.62836 1.89105 1.51584C1.9872 1.40333 2.1046 1.31088 2.23654 1.24381C2.36847 1.17675 2.51234 1.13637 2.65991 1.125H4.72616C4.87637 1.12485 5.02316 1.16982 5.14752 1.25406C5.27188 1.33831 5.36808 1.45795 5.42366 1.5975L5.99366 3C6.04854 3.13633 6.06216 3.28578 6.03283 3.42978C6.00349 3.57379 5.9325 3.706 5.82866 3.81L5.02991 4.61625C5.15468 5.32528 5.49423 5.97888 6.00262 6.48861C6.51101 6.99834 7.16371 7.33961 7.87241 7.46625L8.68616 6.66C8.79172 6.55731 8.92518 6.48797 9.06989 6.46063C9.2146 6.43329 9.36416 6.44915 9.49991 6.50625L10.9137 7.0725C11.0511 7.12983 11.1684 7.22678 11.2505 7.351C11.3327 7.47522 11.376 7.62108 11.3749 7.77V9.75C11.3749 10.0484 11.2564 10.3345 11.0454 10.5455C10.8344 10.7565 10.5483 10.875 10.2499 10.875Z"
                    fill="white"
                  />
                </svg>
                <span className="ms-1">Tanya Properti</span>
              </Link>
            )}
          </div>
          <div className="detail-property-section-a--info-content">
            <div className="property-detail-description">
              {parse(DOMPurify.sanitize(property?.description || ''))}
            </div>
            <div className="property-detail-specification-title">Specifications and Facilities</div>
            <div className="property-detail-specification-content mb-5">
              <div className="property-detail-specification-content-i">
                {property?.appraisal_year ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Tahun Penilaian</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.appraisal_year ? `${property?.appraisal_year}` : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.appraisal_value ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Penilaian harga</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.appraisal_value
                        ? `${formatCurrency(property?.appraisal_value)}`
                        : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.tax_object_sales_year ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Tahun NJOP</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.tax_object_sales_year ? `${property?.tax_object_sales_year}` : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.tax_object_sales_value ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">NJOP</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.tax_object_sales_value
                        ? `${formatCurrency(property?.tax_object_sales_value)}`
                        : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.garage ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Garasi</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.garage ? `${property?.garage} mobil` : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.water_source_name ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Sumber Air</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.water_source_name ? property?.water_source_name : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.electrical_power ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Daya Listrik</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.electrical_power ? property?.electrical_power : '0'}W
                    </div>
                  </div>
                ) : null}

                {property?.bedroom_total ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Kamar Tidur</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.bedroom_total ? property?.bedroom_total : '-'}
                    </div>
                  </div>
                ) : null}

                {property?.bathroom_total ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Kamar Mandi</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.bathroom_total ? property?.bathroom_total : '-'}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="property-detail-specification-content-i">
                {property?.furnished ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Furnish</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.furnished ? 'Furnished' : 'Non Furnished'}
                    </div>
                  </div>
                ) : null}

                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Legalitas</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.legality ? property?.legality : '-'}
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Luas Tanah</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.lot_area} m<sup>2</sup>
                  </div>
                </div>

                {property?.floor_area ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Luas Bangunan</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.floor_area ? property?.floor_area : '-'} m<sup>2</sup>
                    </div>
                  </div>
                ) : null}

                {property?.floor_levels ? (
                  <div className="d-flex flex-row justify-content-between mb-2">
                    <div className="montserrat-medium txt-12px">Lantai</div>
                    <div className="montserrat-bold txt-12px">
                      {property?.floor_levels ? property?.floor_levels : '-'}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="detail-property-section-a--info-content">{renderMap()}</div>
          </div>
        </div>

        <div className="detail-property_separator" />
      </div>
      <div className="detail-property-section-b--container">
        <SliderProperty contents={propertyList || []} />
      </div>
    </main>
  );
}

export default PropertyDetails;
