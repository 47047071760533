/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */

import fetchWrapper from 'utils/fetchWrapper';

const apiUrl = process.env.REACT_APP_API_URL;

async function propertyList({
  page,
  limit,
  sort_by,
  is_asc,
  search,
  property_type_id,
  city_id,
  price_selection_id,
}) {
  try {
    const response = await fetchWrapper.get(
      `${apiUrl}/api/property/search?page=${page}&limit=${limit}&sort_by=${sort_by}&is_asc=${is_asc}${search && search?.length > 0 ? `&search=${search}` : ''}${property_type_id ? `&property_type_id=${property_type_id}` : ''}${city_id ? `&city_id=${city_id}` : ''}${price_selection_id ? `&price_selection_id=${price_selection_id}` : ''}`,
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function propertyListForMap() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/map`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

/// api/property/map

async function detailProperty(id) {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/detail/${id}`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function brands(offset, limit) {
  try {
    const response = await fetchWrapper.get(
      `${apiUrl}/brand?${offset ? `offset=${offset}&` : ''}${limit ? `limit=${limit}&` : 'limit=0'}`,
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function waterSources() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/dropdown/water-source`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function cities() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/dropdown/city`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function propertyTypes() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/dropdown/property-type`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function priceSelections() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/property/dropdown/price-selection`);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

const propertyService = {
  propertyList,
  propertyListForMap,
  brands,
  detailProperty,
  waterSources,
  cities,
  propertyTypes,
  priceSelections,
};

export default propertyService;
