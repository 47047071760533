/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FaAngleLeft } from 'react-icons/fa6';
import PropTypes from 'prop-types';

function ImageLeftNav({ onClick, disabled }) {
  return (
    <button type="button" onClick={onClick} disabled={disabled} className="image-left-nav">
      <FaAngleLeft fontSize={12} color="#ffffff" />
    </button>
  );
}

ImageLeftNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ImageLeftNav;
