/* eslint-disable import/no-unresolved */
import React from 'react';
import logo from 'assets/logo--techpolitan-white.svg';

function NotFound() {
  return (
    <section className="h-screen w-screen bg-app-color-5">
      <div className="d-flex justify-content-center h-100 align-items-center">
        <div className="not-found-container d-flex flex-column align-items-center">
          <div style={{ height: '40px', marginBottom: '2rem' }}>
            <img src={logo} className="h-100" alt="techpolitan" />
          </div>
          <h1 className="montserrat-bold not-found-upper-text text-white">404</h1>
          <h5 className="montserrat-bold text-white">Halaman tidak ditemukan</h5>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
