/* eslint-disable import/no-unresolved */
import fetchWrapper from 'utils/fetchWrapper';

const apiUrl = process.env.REACT_APP_API_URL;

async function getDeviceToken() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/generate-token`);

    if (response) {
      return response;
    }
    throw new Error('Failed to get device token');
  } catch (error) {
    return error;
  }
}

async function getIP() {
  try {
    const response = await fetch('https://ipinfo.io/json');

    // Check if the response is ok (status is in the 200–299 range)

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json(); // Use response.json() if it's a JSON response
    return data;
  } catch (error) {
    throw ('There was a problem with the fetch operation:', error);
  }
}

async function setIncrementView({ deviceToken, ipAddress }) {
  try {
    const response = await fetchWrapper.post(`${apiUrl}/api/increment-vc`, {
      device_token: deviceToken,
      ip_address: ipAddress,
    });

    if (response && response.status_code === 200) {
      return response;
    }
    throw new Error('Failed to post increment view');
  } catch (error) {
    return error;
  }
}

async function getViewers() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/api/view-counter`);

    if (response && response.status_code === 200) {
      return response;
    }
    throw new Error('Failed to get viewers');
  } catch (error) {
    return error;
  }
}

async function banners(offset, limit) {
  try {
    const response = await fetchWrapper.get(
      `${apiUrl}/banner?${offset < 0 ? `offset=${offset}&` : ''}${limit ? `limit=${limit}&` : ''}`,
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function filters(offset, limit) {
  try {
    const response = await fetchWrapper.get(
      `${apiUrl}/users/cms/list/filter?${offset < 0 ? `offset=${offset}&` : ''}${limit ? `limit=${limit}&` : ''}`,
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

const generalService = {
  banners,
  filters,
  getDeviceToken,
  getIP,
  getViewers,
  setIncrementView,
};

export default generalService;
