/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propertyService from 'services/property.service';
import CardProduct from 'components/Cards/CardProduct';
import FilterDropdown from 'components/Dropdowns/FilterDropdown';
// import { ViewOnMapsButton } from 'components/Buttons/Button';
import useViewersStore from 'stores/viewers';
import NoProperty from 'assets/techpolitan-no-property.svg';
import arrowRight from 'assets/arrow--right.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'styles/pages/home/home.css';

function Home() {
  const { viewers } = useViewersStore((state) => state);
  const [payload, setPayload] = useState({
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    price_selection_id: null,
  });
  const [propertyList, setPropertyList] = useState([]);
  const [propertyListTotal, setPropertyListTotal] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [propertyTypesList, setPropertyTypesList] = useState([]);
  const [priceSelectionsList, setPriceSelectionsList] = useState([]);
  const [seeMore, setSeeMore] = useState(0);

  async function fetchProperty() {
    try {
      const payloadData = { ...payload };
      const response = await propertyService.propertyList(payloadData);
      setPropertyList(response?.data?.content);
      setPropertyListTotal(response?.data?.total_all_elements);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchSeeMoreProperty() {
    try {
      const payloadData = { ...payload };
      const response = await propertyService.propertyList(payloadData);
      if (response && response.data && response.data.content) {
        setPropertyList((prevValue) => [...response.data.content, ...prevValue]);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  function fetchSeeMore() {
    setPayload({ ...payload, page: payload.page + 1 });
    setSeeMore(seeMore + 1);
  }

  async function fetchCities() {
    try {
      const response = await propertyService.cities();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id?.toString(), label: x?.name };
      });

      setCityList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchPropertyTypes() {
    try {
      const response = await propertyService.propertyTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id?.toString(), label: x?.name };
      });
      setPropertyTypesList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchPriceSelections() {
    try {
      const response = await propertyService.priceSelections();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id?.toString(), label: x?.name };
      });
      setPriceSelectionsList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  function handleDropdown(param, value) {
    setPayload({ ...payload, [param]: value });
  }

  function handleClearFilter(param) {
    setPayload({ ...payload, [param]: null });
  }

  useEffect(() => {
    fetchProperty();
  }, [payload.price_selection_id, payload.property_type_id, payload.city_id]);

  useEffect(() => {
    fetchCities();
    fetchPropertyTypes();
    fetchPriceSelections();
  }, []);

  useEffect(() => {
    if (seeMore > 0) {
      fetchSeeMoreProperty();
    }
  }, [seeMore]);

  return (
    <main className="main-container">
      <div className="home-section__a">
        <div className="home-section__a-upper">
          {/* <h1 className="home-section__a-title">Techpolitan: Your Gateway</h1>
          <h1 className="home-section__a-title">to Underpriced Properties</h1>
          <p className="home-section__a-article">
            Masuki dunia investasi properti dengan harga terjangkau! Dapatkan penawaran terbaik dan
            raih impian properti Anda sekarang!
          </p> */}
        </div>
        <div className="home-section__a-bottom">
          <div className="card-filter-outer">
            <div className="card-filter-outer__text-box">
              <h3 className="card-filter-outer__text">
                Investasi Properti Menjanjikan di{' '}
                <span className="app-color-text-2">Lokasi Strategis!</span>
              </h3>
              <h4 className="card-filter-outer__text">
                <span className="app-color-text-2">{viewers}</span> people are viewing this page
              </h4>
            </div>

            <div className="card-filter-inner">
              <h6 className="card-filter-inner__title">
                Investasi Properti Menjanjikan di{' '}
                <span className="app-color-text-2">Lokasi Strategis!</span>
              </h6>
              <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Mencari apa"
                      paramName="property_type_id"
                      id="property_type_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={propertyTypesList}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Lokasi"
                      paramName="city_id"
                      id="city_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={cityList}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 m-0 p-0 filter-property-container">
                    <FilterDropdown
                      name="Harga"
                      paramName="price_selection_id"
                      id="price_selection_id"
                      handleDropdown={handleDropdown}
                      handleClearFilter={handleClearFilter}
                      options={priceSelectionsList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section__b">
        {propertyList && propertyList?.length > 0 && (
          <div className="home-section__b-properties">
            {propertyList.map((property) => {
              return <CardProduct key={`content-${property?.id}`} detail={property} />;
            })}
          </div>
        )}

        {(!propertyList || propertyList.length === 0) && (
          <div className="d-flex flex-column justify-content-start align-items-center w-100 my-5">
            <img src={NoProperty} alt="no-data" />
            <p
              style={{
                marginTop: '26.16px',
                lineHeight: '19.5px',
                letterSpacing: '-0.43px',
                color: '#979797',
                textAlign: 'center',
              }}
            >
              Properti tidak ditemukan. Belum ada hasil yang sesuai dengan pencarian Anda.
            </p>
          </div>
        )}

        <div className="d-flex flex-row justify-content-center">
          {propertyListTotal === propertyList?.length ? null : (
            <div className="d-flex flex-row justify-content-center">
              <button type="button" onClick={fetchSeeMore} className="btn-see-more">
                Lihat Lebih
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="home-section__c">
        <div className="home-section__c-content">
          <h4 className="text">
            Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
          </h4>
          <Link to="/property-cooperation" className="cta-button">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />
            <p className="text">Kerjasama Properti</p>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Home;
