/* eslint-disable import/no-unresolved */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'pages/notFound';
import RootLayout from 'components/Layouts/RootLayout';

import Home from 'pages/home';
import DetailProperties from 'pages/properties/details';
import PropertyCooperation from 'pages/propertyCooperation';
// import ViewOnMaps from 'pages/view-on-maps';
import ContactUs from 'pages/contactUs/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/App.css';
import 'styles/responsive.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="/properties/:id" element={<DetailProperties />} />
        <Route path="/property-cooperation" element={<PropertyCooperation />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
