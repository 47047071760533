/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import PropTypes from 'prop-types';

function ImageRightNav({ onClick, disabled }) {
  return (
    <button type="button" onClick={onClick} disabled={disabled} className="image-right-nav">
      <FaAngleRight fontSize="12" color="#ffffff" />
    </button>
  );
}

ImageRightNav.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ImageRightNav;
