/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="filter-dropdown__toggle"
  >
    <span>{children}</span>
    <svg
      className="ms-2"
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98638 6.97066C5.66876 6.97102 5.36636 6.84867 5.15646 6.63488L0.447179 1.83402C0.137612 1.50314 0.179181 1.01114 0.540802 0.725881C0.902424 0.440621 1.45183 0.466445 1.77822 0.784045L5.89614 4.98221C5.91892 5.00553 5.95181 5.0189 5.98638 5.0189C6.02095 5.0189 6.05383 5.00553 6.07662 4.98221L10.1945 0.784045C10.4013 0.563057 10.7253 0.45984 11.0412 0.514336C11.357 0.568832 11.6152 0.77249 11.7158 1.0465C11.8164 1.32052 11.7436 1.62189 11.5256 1.83402L6.81774 6.63316C6.60748 6.84746 6.30467 6.97039 5.98638 6.97066Z"
        fill="#333333"
      />
    </svg>
  </a>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomToggle;
