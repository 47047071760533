/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Headers/Header';
import Footer from 'components/Footers/Footer';
import ScrollToTop from 'components/Customs/ScrollToTop';
import generalService from 'services/general.service';
import { useViewersStore } from 'stores';
import { parseCookies, setCookie } from 'nookies';

const cookies = parseCookies();

export default function RootLayout() {
  const { setViewers } = useViewersStore((state) => state);
  const [firstLoad, setFirstLoad] = useState(true);
  const [ipAddress, setIpAddress] = useState(cookies?.ip_address ?? null);
  const [deviceToken, setDeviceToken] = useState(cookies?.device_token ?? null);

  const fetchIP = async () => {
    try {
      const response = await generalService.getIP();
      setIpAddress(response?.ip);
      setCookie(null, 'ip_address', response?.ip, { path: '/' });
    } catch (error) {
      throw new Error('Failed to fetch IP');
    }
  };

  const fetchToken = async () => {
    try {
      const response = await generalService.getDeviceToken();

      if (response && response.status_code === 200) {
        setDeviceToken(response.data.device_token);
        setCookie(null, 'device_token', response.data.device_token, { path: '/' });
      }
    } catch (error) {
      throw new Error('Failed to fetch device token');
    }
  };

  const fetchViewers = async () => {
    try {
      const response = await generalService.getViewers();
      setCookie(null, 'viewers', response?.data?.view_count, { path: '/' });
      setViewers(response?.data?.view_count);
    } catch (error) {
      throw new Error('Failed to fetch viewers');
    }
  };

  const incrementView = async () => {
    try {
      const response = await generalService.setIncrementView({
        deviceToken,
        ipAddress,
      });

      if (response && response.status_code === 200) {
        fetchViewers();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (firstLoad) {
      if (!cookies?.device_token && !cookies?.IP) {
        fetchToken();
        fetchIP();
      }
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    if (deviceToken && ipAddress) {
      incrementView();
    }
  }, [deviceToken, ipAddress]);

  return (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
