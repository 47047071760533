/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import arrowRight from 'assets/techpolitan-arrow-right.svg';
import backgroundDesktop from 'assets/background-desktop.png';
import backgroundTablet from 'assets/background-tablet.png';
import backgroundMobile from 'assets/background-mobile.png';
import decorDesktop from 'assets/decor-desktop.svg';
import decorTablet from 'assets/decor-tablet.svg';
import decorMobile from 'assets/decor-mobile.svg';
import logo from 'assets/logo--techpolitan-blue.svg';
import 'styles/pages/property-cooperation/property-cooperation.css';

function PropertyCooperation() {
  return (
    <main>
      <div className="property-coop__section-a">
        <img src={logo} alt="logo" className="logo" />
        <img src={backgroundDesktop} alt="" className="background-desktop" />
        <img src={backgroundMobile} alt="" className="background-mobile" />
        <img src={backgroundTablet} alt="" className="background-tablet" />
        <h1 className="property-coop__text-i">Your Gateway to</h1>
        <div className="property-coop__big-text-ii-group">
          <div className="property-coop__big-text-ii-left">
            <h1 className="property-coop__text-ii left">Underpriced</h1>
            <img src={decorDesktop} alt="" className="decor-desktop" />
            <img src={decorTablet} alt="" className="decor-tablet" />
            <img src={decorMobile} alt="" className="decor-mobile" />
          </div>
          <div className="property-coop__big-text-ii-spacer" />
          <h1 className="property-coop__text-ii right">Properties</h1>
        </div>

        <p className="property-coop__text-iii">
          Masuki dunia investasi properti dengan harga terjangkau! Dapatkan penawaran terbaik dan
          raih impian properti Anda sekarang!
        </p>

        <Link to="/contact-us" className="btn-join-now">
          <img src={arrowRight} alt="arrow-right" />
          <span>Bergabung Sekarang</span>
        </Link>
      </div>
    </main>
  );
}

export default PropertyCooperation;
