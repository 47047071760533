import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { FaTimesCircle } from 'react-icons/fa';
import CustomMenu from './FilterDropdownCustomMenu';
import CustomToggle from './FilterDropdownCustomToggle';
import '../../styles/components/dropdowns/filter-dropdown.css';

function FilterDropdown({ name, id, options, paramName, handleDropdown, handleClearFilter }) {
  const [selectedOption, setSelectedOption] = useState(name);
  const [filterStatus, setFilterStatus] = useState(false);
  const handleOptionSelect = (label, value) => {
    setSelectedOption(label);
    handleDropdown(paramName, value);
    setFilterStatus(true);
  };

  const clearFilter = () => {
    setSelectedOption(name);
    setFilterStatus(false);
    handleClearFilter(paramName);
  };

  return (
    <Dropdown className="w-100 h-100" id={`filter-dropdown__${id}`}>
      {filterStatus ? (
        <button type="button" className="clear-filter-btn" onClick={clearFilter}>
          <FaTimesCircle />
        </button>
      ) : null}

      <Dropdown.Toggle as={CustomToggle} id={id}>
        {selectedOption}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} bsPrefix="dropdown-menu filter-dropdown__menu">
        {options
          ? options.map((option, index) => (
              <Dropdown.Item
                bsPrefix="dropdown-item filter-dropdown__menu-item"
                key={option.id}
                eventKey={index}
                onClick={() => handleOptionSelect(option.label, option.id)}
              >
                {option.label}
              </Dropdown.Item>
            ))
          : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

FilterDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  paramName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleDropdown: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
};

export default FilterDropdown;
